import axios from "axios";
import store from "./store";

const getQueryResults = (first = false) => {
  const params = {
    query: store.state.querySelection,
    amt: 500, //store.state.queryAmt,
    diff: store.state.queryDifficulty,
    map: store.state.queryMap,
    plat: store.state.queryPlatform,
    hero: store.state.queryHero,
    s: store.state.querySeason
  }

  if (params.diff != null && params.map != null) {
    store.dispatch('storeCachedResults', { board: params.query + '_' + params.diff,
      results: [{ Id: 0, Name: 'Loading Leaderboard', SessionTime: '1' }] });
  } else {
    if (!first)
      throw "Error: Client not ready for requests";
  }

  axios.get('https://grworker.dundef.com', { params }, { timeout: 10000 })
    .then((response) => {
      if (Array.isArray(response.data.result))
      {
        let queryData = response.data.result;
        if (params.query === 'fastest') {
          queryData.forEach((row, index) => {
            queryData[index].Moment = new Date(queryData[index].Moment).toUTCString().split(' ').slice(1, 5).join(' ');
            queryData[index].BossMaps = { Demon_Lord: queryData[index].Map_Boss_DemonLord, Goblin_Mech: queryData[index].Map_Boss_GoblinMech, Ancient_Dragon: queryData[index].Map_Boss_AncientDragon};
            queryData[index].NormalMaps = {};
            for (const [key, value] of Object.entries(queryData[index])) {
              if (!key.includes('Boss') && !key.includes('NormalMaps') && key.includes('Map') && value != null) {
                queryData[index].NormalMaps[key] = value;
              }
            }
          })
        }
        return queryData;
      } else {
        return [{ Id: 0, Name: 'No Leaderboard times were found. Please refresh again later!', SessionTime: '1' }];
      }
    })
    .then((data) => {
      store.dispatch('storeCachedResults', { board: params.query + '_' + params.diff, results: data });
    })
    .catch((error) => {
      console.log(error);
    });
  return params;
}

const getPlayerResults = (id) => {
  const params = {
    query: 'player',
    id: id
  }

  if (id == null) {
    throw "Error: Id not found.";
  } else if (id == 0 ) {
    store.dispatch('storeCachedResults', { board: 'player_0',
      results: [{ id: 0, Name: 'Player was not found and/or no completed scores were available for display.', SessionTime: '1' }] });
  } else {
    axios.get('https://grworker.dundef.com', { params }, { timeout: 10000 })
      .then((response) => {
        if (Array.isArray(response.data.result))
        {
          let queryData = response.data.result;
          if (params.query === 'player') {
            queryData.forEach((row, index) => {
              queryData[index].Moment = new Date(queryData[index].Moment).toUTCString().split(' ').slice(1, 5).join(' ');
              queryData[index].BossMaps = { Demon_Lord: queryData[index].Map_Boss_DemonLord, Goblin_Mech: queryData[index].Map_Boss_GoblinMech, Ancient_Dragon: queryData[index].Map_Boss_AncientDragon};
              queryData[index].NormalMaps = {};
              for (const [key, value] of Object.entries(queryData[index])) {
                if (!key.includes('Boss') && !key.includes('NormalMaps') && key.includes('Map') && value != null) {
                  queryData[index].NormalMaps[key] = value;
                }
              }
            })
          }
          return queryData;
        } else {
          return [{ Id: 0, Difficulty: 'No Leaderboard times were found. Please refresh again later!', SessionTime: '1' }];
        }
      })
      .then((data) => {
        store.dispatch('storeCachedResults', { board: 'player_' + params.id, results: data });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return params;
}

const getPlayerId = (pfid) => {
  const params = {
    query: 'pflookup',
    pfid: pfid
  }
 
  if (pfid == null) {
    throw "Error: Lookup failed.";
  } else {
    return axios.get('https://grworker.dundef.com', { params }, { timeout: 10000 })
      .then((response) => {
        let queryData = response.data.result;
        return queryData[0].Id;
      })
      .catch((error) => {
        console.log(error);
        return "0";
      })
  }
}

export {
  getQueryResults,
  getPlayerResults,
  getPlayerId
}