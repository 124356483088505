<template>
  <q-page-container>
    <q-page class="flex flex-center">
      <div id="q-app" style="min-height: 94vh;">
        <template v-if="id != 0">
          <div class="q-pa-md">
            <br>
            <br>
            <q-virtual-scroll type="table" style="max-height: 88vh" :virtual-scroll-item-size="48" :virtual-scroll-sticky-size-start="48"
              :virtual-scroll-sticky-size-end="32" :items="playerData">
              <template v-slot:before>
                <thead class="thead-sticky text-left">
                  <tr>
                    <th v-for="col in columnData" :key="'1--' + col.name" class="text-center">
                      {{ col.name }}
                    </th>
                  </tr>
                </thead>
              </template>

              <template v-slot="{ item: row, index }">
                <tr :key="index">
                  <td v-for="col in columnData" :key="index + '-' + col.name" class="text-center">
                    <span v-if="col.name == 'Time'">
                      {{ timeConverter(row[col.prop], sessionTime) }}
                    </span>
                    <span v-else-if="col.name == 'Hero' && heroIcons == true">
                      <img :src="`/images/${row[col.prop]}_Small.png`">
                    </span>
                    <span v-else-if="col.name == 'Maps'">
                      <q-btn>
                        <img src='/images/hero.png'>
                        <q-tooltip class="bg-deep-purple text-body2">
                          <div :id="`maps_${index}`" class="">
                            <span v-for="time, map in row[col.prop]" :key="time">{{ map.slice(4).replace(/([A-Z])/g, ' $1').trim() }}: {{ timeConverter(time, mapTime) }}<br></span>
                          </div>
                        </q-tooltip>
                      </q-btn>
                    </span>
                    <span v-else-if="col.name == 'Bosses'">
                      <q-btn>
                        <img src='/images/boss.png'>
                        <q-tooltip class="bg-negative text-body2">
                          <div :id="`boss_${index}`" class="">
                            <span v-for="time, map in row[col.prop]" :key="time">{{ map.replaceAll('_', ' ') }}: {{ timeConverter(time, mapTime) }}<br></span>
                          </div>
                        </q-tooltip>
                      </q-btn>
                    </span>
                    <span v-else>{{ row[col.prop] }}</span>
                  </td>
                </tr>
              </template>
            </q-virtual-scroll>
          </div>
        </template>
        <template v-else>
          <br>
          <br>
          <div class="q-pa-md q-gutter-sm">
            <q-banner rounded class="bg-deep-purple text-white">
              <div>No scores were found with the provided player information. Only solo complete victories are available on the leaderboard and can take up to two hours to appear. Good luck!</div>
            </q-banner>
              <br>
            <q-banner rounded class="bg-grey-10 text-white">
              <div>This page will auto-redirect in 30 seconds to the top times on the home page. Click <q-btn class="bg-white text-primary" @click="manualRedirect();">Here</q-btn> to navigate there instantly.</div>
            </q-banner>
          </div>
        </template>
      </div>
      <q-page-sticky v-if="id != 0" expand position="top">
        <q-toolbar class="bg-grey-10 text-white">
          <img src="/images/hero.png">
          <q-toolbar-title>
            {{ playerData[0].Name }}
          </q-toolbar-title>
        </q-toolbar>
      </q-page-sticky>
      <q-page-sticky v-else expand position="top">
        <q-toolbar class="bg-grey-10 text-white">
          <q-toolbar-title>
            Player Not Found
          </q-toolbar-title>
        </q-toolbar>
      </q-page-sticky>
    </q-page>
  </q-page-container>
</template>
<style>
img {
  max-width:32px;
}
</style>

<script>
import { mapState } from 'vuex';
import { getPlayerResults } from '../js/query';
import { timeConverter } from '../js/functions';

export default {
  name: 'Player',
  props: {
    sessionTime: String,
    mapTime: String,
    heroIcons: Boolean,
    id: {
      required: true,
    }
  },
  data() {
    return {
      columnData: [ 
        { name: 'Difficulty', align: 'center', prop: 'Difficulty' },
        { name: 'Time', align: 'center', prop: 'SessionTime' },
        { name: 'Hero', align: 'center', prop: 'HeroUsed' },
        { name: 'Date', align: 'center', prop: 'Moment' },
        { name: 'Bosses', align: 'center', prop: 'BossMaps'},
        { name: 'Maps', align: 'center', prop: 'NormalMaps' },
      ],
      timeConverter
    }
  },
  computed: {
    ...mapState(['playerData']),
  },
  methods: {
    manualRedirect() {
      clearTimeout(this.redirectTimer);
      this.$router.push({ path: '/' });
    }
  },
  mounted() {
    if (this.id != 0) {
      getPlayerResults(this.id);
    } else {
      this.redirectTimer = setTimeout( () => this.$router.push({ path: '/' }), 30000);
    }
  },
}
</script>
