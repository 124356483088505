<template>
  <q-page-container>
    <q-page class="flex flex-center">
      <div id="q-app" style="min-height: 94vh;">
        <br>
        <br>
        <q-list padding style="max-width: 1280px">
          <q-item-label header>Information</q-item-label>
          <q-item>
            <q-item-section avatar top>
              <q-avatar icon="help" color="deep-purple" text-color="white"></q-avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label lines="1">Requirements</q-item-label>
              <q-item-label caption>In order for a time to appear the player must have been achieved victory, while playing solo, all in one session, within 2 hours, and must be online.</q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section avatar top>
              <q-avatar icon="help" color="deep-purple" text-color="white"></q-avatar>
            </q-item-section>
              <q-item-section>
              <q-item-label lines="1">Times Displayed</q-item-label>
              <q-item-label caption>The top times leaderboard(home page) will only display the best time a player has achieved. However the individual player scoreboards can display up to 10 of each difficulty.</q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section avatar top>
              <q-avatar icon="help" color="deep-purple" text-color="white"></q-avatar>
            </q-item-section>
              <q-item-section>
              <q-item-label lines="1">Time to Appear</q-item-label>
              <q-item-label caption>Due to metrics having to go through multiple systems for storage and then retrieval it can take upwards of 2 hours for your score to appear. Though it tends to display it quicker than that.</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
        <q-separator spaced></q-separator>
        <q-item-label header>Help</q-item-label>
        <q-item>
          <q-item-section avatar top>
            <q-avatar icon="help" color="deep-purple" text-color="white"></q-avatar>
          </q-item-section>
            <q-item-section>
            <q-item-label lines="1">How do I see other difficulties or enable dark mode?</q-item-label>
            <q-item-label caption>Click the stack icon (<q-icon class="material-icons">menu</q-icon>) in the upper left!</q-item-label>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section avatar top>
            <q-avatar icon="help" color="deep-purple" text-color="white"></q-avatar>
          </q-item-section>
            <q-item-section>
            <q-item-label lines="1">I think something's wrong!</q-item-label>
            <q-item-label caption>Please contact us on <a href="https://discord.com/invite/dd2">Discord</a> or through <a href="https://chromatic.zendesk.com/hc/en-us/requests/new">Zendesk</a> support. Provide as much detail as possible.</q-item-label>
          </q-item-section>
        </q-item>
        <q-separator spaced></q-separator>
        <q-item-label header>Other</q-item-label>
        <q-item>
          <q-item-section avatar top>
            <q-avatar icon="help" color="deep-purple" text-color="white"></q-avatar>
          </q-item-section>
            <q-item-section>
            <q-item-label lines="1">Rounding</q-item-label>
            <q-item-label caption>For simplicy the leaderboards round every time displayed individually to 3 digits. <strong>Due to this rounding the Map + Boss Times will likely not equal the Session Time shown.</strong> The actual times recorded are more precise. </q-item-label>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section avatar top>
            <q-avatar icon="help" color="deep-purple" text-color="white"></q-avatar>
          </q-item-section>
            <q-item-section>
            <q-item-label lines="1">Load Times</q-item-label>
            <q-item-label caption>The Session Timer does not include load times in its recording.</q-item-label>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section avatar top>
            <q-avatar icon="help" color="deep-purple" text-color="white"></q-avatar>
          </q-item-section>
            <q-item-section>
            <q-item-label lines="1">Recording</q-item-label>
            <q-item-label caption>These leaderboards are for display purposes only and even if the site becomes unavailable the times are still recorded. The times are stored in multiple different locations and systems.</q-item-label>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section avatar top>
            <q-avatar icon="help" color="deep-purple" text-color="white"></q-avatar>
          </q-item-section>
            <q-item-section>
            <q-item-label lines="1">Player Names</q-item-label>
            <q-item-label caption>Names listed on the leaderboard are what they were at the time an entry first made its appearance. Names will update over time if a player continues to get new or better leaderboard times.</q-item-label>
          </q-item-section>
        </q-item>
      </div>
      <q-page-sticky expand position="top">
        <q-toolbar class="bg-grey-10 text-white">
          <img src="../../public/images/information.png">
          <q-toolbar-title>
            General Information and Help
          </q-toolbar-title>
        </q-toolbar>
      </q-page-sticky>
    </q-page>
  </q-page-container>
</template>

<script>
export default {
  name: 'Help',
}
</script>
