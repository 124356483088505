<template>
  <link rel="shortcut icon" type="image/png" href="/images/favicon.png"/>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated class="glossy bg-deep-purple">
      <q-toolbar>
        <q-btn flat dense round @click="leftDrawerOpen = !leftDrawerOpen" aria-label="Options" icon="menu"/>
        <q-toolbar-title>
          DDGR Leaderboard <template v-if="$route.name != 'Main'"><router-link to="/" id="home"><q-icon class="material-icons">home</q-icon></router-link></template>
        </q-toolbar-title>
        <router-link to="/help" id="home"><q-icon class="material-icons" size="sm">help</q-icon></router-link>
      </q-toolbar>
    </q-header>

    <q-drawer v-model="leftDrawerOpen" bordered class="drawer" :width="265">
      <q-list>
        <q-item-label header>Leaderboard Options</q-item-label>
        <q-item>
          <q-item-section>
            <q-item-label>Selection</q-item-label>
            <div class="q-gutter-sm">
              <q-option-group size="xs" dense color="purple" :options="optsSelection" type="radio" v-model="lbSelection" inline @update:model-value="changeQueryOption('Selection', lbSelection)"></q-option-group>
            </div>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>Difficulty</q-item-label>
            <div class="q-gutter-sm">
              <q-option-group size="xs" dense color="purple" :options="optsDifficulty" type="radio" v-model="lbDifficulty" inline @update:model-value="changeQueryOption('Difficulty', lbDifficulty)"></q-option-group>
            </div>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>Hero</q-item-label>
            <div class="q-gutter-sm">
              <q-option-group size="xs" dense color="purple" :options="optsHero" type="radio" v-model="lbHero" inline @update:model-value="changeQueryOption('Hero', lbHero)">
                <template v-slot:label="opt">
                  <template v-if="opt.label != 'All'"> 
                    <img :src="`/images/${opt.label}_Small.png`" class="img_herosel"><span>{{ opt.label }}</span>
                  </template>
                  <template v-else>
                    <span>All</span>
                  </template>
                </template>
              </q-option-group>
            </div>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>Map</q-item-label>
            <div class="q-gutter-sm">
              <q-select outlined color="purple" :options="optsMap" v-model="lbMapSelected"  @update:model-value="changeQueryOption('Map', lbMapSelected.value)"></q-select>
            </div>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>Event</q-item-label>
            <div class="q-gutter-sm">
              <q-select outlined color="purple" :options="optsSeason" v-model="lbSeason"  @update:model-value="changeQueryOption('Season', lbSeason.value)"></q-select>
            </div>
          </q-item-section>
        </q-item>
        <br>
        <q-separator></q-separator>
        <q-item-label header>Display Options</q-item-label>
        <q-item>
          <q-item-section>
            <q-item-label>Session Time</q-item-label>
            <div class="q-gutter-sm">
              <q-option-group size="xs" dense color="purple" :options="optsTime" type="radio" v-model="optionSessionTime" inline @update:model-value="sessionTimeConversion();"></q-option-group>
            </div>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>Boss and Map Times</q-item-label>
            <div class="q-gutter-sm">
              <q-option-group size="xs" dense color="purple" :options="optsTime" type="radio" v-model="optionMapTime" inline @update:model-value="mapTimeConversion();"></q-option-group>
            </div>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>Light Mode</q-item-label>
            <div class="q-gutter-sm">
              <q-toggle size="s" color="purple" v-model="optionLightMode" inline @update:model-value="toggleLightMode()"></q-toggle>
            </div>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>Hero Icons</q-item-label>
            <div class="q-gutter-sm">
              <q-toggle size="s" color="purple" v-model="optionHeroIcons" inline @update:model-value="toggleHeroIcons()"></q-toggle>
            </div>
          </q-item-section>
        </q-item>
        <!--<q-item>
          <q-item-section>
            <q-item-label>Platform</q-item-label>
            <div class="q-gutter-sm">
              <q-option-group size="xs" dense :options="optsPlat" type="radio" v-model="lbPlat" inline @update:model-value="lbChange(true)"></q-option-group>
            </div>
          </q-item-section>
        </q-item>-->
      </q-list>
    </q-drawer>
    <router-view :sessionTime=optionSessionTime :mapTime=optionMapTime :heroIcons=optionHeroIcons :mapSelected=lbMapSelected.label :seasonSelected=lbSeason.label />
  </q-layout>
</template>

<style>
.body--light .drawer {
  background: #f5f5f5 !important;
}

#home {
  text-decoration: none;
  color: white;
}

body {
  cursor: url('../public/images/cursor.png'), auto;
}

.img_herosel {
  max-width:16px;
  margin-left: -5px;
  margin-bottom: -3px;
  margin-right: 2px;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #9e8db4;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #955bdb;
}
</style>


<script>
import { ref } from 'vue'
import { mapState } from 'vuex';
import { getQueryResults } from './js/query';
import store from './js/store';

import { Dark } from 'quasar';

export default {
  name: 'App',
  setup () {
    const optionSessionTime = ref(localStorage.getItem('optionSessionTime') || 'Split');
    const optionMapTime = ref(localStorage.getItem('optionMapTime') || 'Seconds');
    const optionLightMode = ref(localStorage.getItem('optionLightMode')==='true');
    const optionHeroIcons = ref(localStorage.getItem('optionHeroIcons')==='true');

    const changeQueryOption = (name, value) => {
      store.dispatch('storeQueryOption', { name, value });
      store.dispatch('clearLeaderboard');
      getQueryResults(true);
    };

    const toggleLightMode = () => {
      Dark.set(!optionLightMode.value);
      window.localStorage.setItem('optionLightMode', optionLightMode.value);
    };

    const toggleHeroIcons = () => {
      window.localStorage.setItem('optionHeroIcons', optionHeroIcons.value);
    };

    const sessionTimeConversion = () => {
      window.localStorage.setItem('optionSessionTime', optionSessionTime.value);
    };

    const mapTimeConversion = () => {
      window.localStorage.setItem('optionMapTime', optionMapTime.value);
    };

    return {
      optsDifficulty: [
        { label: 'Medium', value: 'medium' }, 
        { label: 'Hard', value: 'hard' }
        ],
      optsSelection: [
        { label: 'Fastest', value: 'fastest' }, 
        //{ label: 'Player', value: 'player', disable: true}
        ],
      optsPlatform: [
        { label: 'All', value: 'ALL' },
        ],
      optsSeason: [
        { label: 'Live', value: 'Live' },
        { label: 'Dungeon Dash', value: 'dash' },
        ],
      optsHero: [
        { label: 'All', value: 'ALL' },
        { label: 'Squire', value: 'Squire' , icon: 'house'},
        { label: 'Apprentice', value: 'Apprentice' },
        { label: 'Huntress', value: 'Huntress' },
        { label: 'Monk', value: 'Monk' },
        { label: 'Warden', value: 'Warden' }
        ],
      optsMap: [
        { label: 'All', value: 'ALL' },
        { label: '(Boss) Demon Lord', value: 'Map_Boss_DemonLord' }, 
        { label: '(Boss) Goblin Mech', value: 'Map_Boss_GoblinMech' }, 
        { label: '(Boss) Ancient Dragon', value: 'Map_Boss_AncientDragon' }, 
        { label: 'Alchemical Laboratory', value: 'Map_AlchemicalLaboratory' }, 
        { label: 'Ancient Mines', value: 'Map_AncientMines' }, 
        { label: 'Arcane Library', value: 'Map_ArcaneLibrary' }, 
        { label: 'Endless Spires', value: 'Map_EndlessSpires' }, 
        { label: 'Foundries And Forges', value: 'Map_FoundriesAndForges' }, 
        { label: 'Glitterhelm', value: 'Map_Glitterhelm' }, 
        { label: 'Lava Mines', value: 'Map_LavaMines' }, 
        { label: 'Magma Mines', value: 'Map_MagmaMines' }, 
        { label: 'Magus Quarters', value: 'Map_MagusQuarters' }, 
        { label: 'Royal Gardens', value: 'Map_RoyalGardens' },
        { label: 'The Deeper Well', value: 'Map_TheDeeperWell' }, 
        { label: 'The Mill', value: 'Map_TheMill' }, 
        { label: 'The Outpost', value: 'Map_TheOutpost' },
        { label: 'The Promenade', value: 'Map_ThePromenade' }, 
        { label: 'The Ramparts', value: 'Map_TheRamparts' }, 
        { label: 'The Summit', value: 'Map_TheSummit' }, 
        { label: 'The Throne Room', value: 'Map_TheThroneRoom' }, 
        { label: 'Tornado High Lands', value: 'Map_TornadoHighLands' }, 
      ],
      lbSelection: ref(store.state.querySelection),
      lbDifficulty: ref(store.state.queryDifficulty),
      lbMapSelected: ref(store.state.queryMap),
      lbPlatform: ref(store.state.queryPlatfrom),
      lbSeason: ref(store.state.querySeason),
      lbHero: ref(store.state.queryHero),
      leftDrawerOpen: ref(false),
      optsTime: [
        { label: 'Split', value: 'Split' },
        { label: 'Compact', value: 'Compact' },
        { label: 'Seconds', value: 'Seconds' }
      ],
      optionSessionTime,
      optionMapTime,
      optionLightMode,
      optionHeroIcons,
      changeQueryOption,
      toggleLightMode,
      toggleHeroIcons,
      sessionTimeConversion,
      mapTimeConversion
    }
  },
  computed: {
    ...mapState(['queryAmt']),
  },
  /*watch: {
    queryAmt() {
      if (this.queryAmt > 100) {
        this.lbChange(false);
      }
    }
  },*/
  created() {
    this.toggleLightMode();
  },
}
</script>
