const timeConverter = (time, convert) => {
  if (convert == 'Split') {
    let newTime = new Date(time * 1000).toISOString().slice(11, -1);
    let t = newTime.split(/:|\./),
      s = ['h', 'm', 's', 'ms'],
      i = 0;
    for( ; i < t.length; i++ ) {
        t[i] = parseFloat(t[i]) == 0 ? '' : parseFloat(t[i]) + s[i + s.length - t.length];
        t[i] = t[i].length > 0 && t[i].length < 3 ? '0' + t[i] : t[i];
        t[i] = t[i].length == 4 ? '0' + t[i] : t[i];
    }
    return t.join(' ');
  } else if (convert == 'Compact') {
    return new Date(time * 1000).toISOString().slice(11, -1);
  }

  return time;
}

function capitalizeStr(str) {
  return str[0].toUpperCase() + str.slice(1);
}

export {
  timeConverter,
  capitalizeStr
}
