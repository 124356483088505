import { createStore } from 'vuex';

const store = createStore({
  state: {
    cachedResults: new Map(),
    boardData: [{ id: 0, name: 'Please select a leaderboard to view!'}],
    playerData: [{ id: 0, name: 'Please select a player to view!'}],
    queryAmt: 100,
    querySelection: 'fastest',
    queryDifficulty: 'medium',
    queryMap: 'ALL',
    queryPlatform: 'ALL',
    queryHero: 'ALL',
    querySeason: 'Live',
  },
  mutations: {
    updateCachedResults (state, value) {
      state.cachedResults.delete(value.board);
      state.cachedResults.set(value.board, value.results);
      if (!value.board.includes('player')) {
        state.boardData = value.results;
      } else {
        state.playerData = value.results;
      }
    },
    updateQueryAmt (state) {
      if (state.queryAmt <= 400) {
        state.queryAmt = state.queryAmt + 100;
      }
    },
    updateQueryOption (state, option) {
      state[`query` + option.name] = option.value;
    },
    emptyLeaderboard (state) {
      state.queryAmt = 100;
      state.boardData = [{ id: 0, name: 'Leaderboard Loading...'}];
    },
  },
  actions: {
    storeCachedResults ({ commit }, payload) {
      commit('updateCachedResults', payload);
    },
    storeQueryAmt ({ commit }) {
      commit('updateQueryAmt');
    },
    storeQueryOption ({ commit }, payload) {
      commit('updateQueryOption', payload)
    },
    clearLeaderboard ({ commit }) {
      commit('emptyLeaderboard')
    }
  }
})

export default store;
