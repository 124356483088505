<template>
  <q-page-container>
    <q-page class="flex flex-center">
      <div id="q-app" style="min-height: 94vh;">
        <div class="q-pa-md">
          <br>
          <br>
          <q-virtual-scroll type="table" style="max-height: 88vh" :virtual-scroll-item-size="48" :virtual-scroll-sticky-size-start="48"
            :virtual-scroll-sticky-size-end="32" :items="boardData" @virtual-scroll="scroll">
            <template v-slot:before>
              <thead class="thead-sticky text-left">
                <tr>
                  <th width="15px">Place</th>
                  <th v-for="col in columnData" :key="'1--' + col.name" class="text-center">
                    <template v-if="queryMap == 'ALL'">
                      {{ col.name }}
                    </template>
                    <template v-else>
                      <template v-if="col.name != 'Bosses' && col.name != 'Maps'">
                        {{ col.name }}
                      </template>
                    </template>
                  </th>
                </tr>
              </thead>
            </template>

            <template v-slot="{ item: row, index }">
              <tr :key="index">
                <td>#{{ index+1 }}</td>
                <template v-if="row.Id != 0">
                  <td v-for="col in columnData" :key="index + '-' + col.name" class="text-center">
                    <span v-if="col.name == 'Time'">
                      {{ timeConverter(row[col.prop], sessionTime) }}
                    </span>
                    <span v-else-if="col.name == 'Hero' && heroIcons == true">
                      <img :src="`./images/${row[col.prop]}_Small.png`">
                    </span>
                    <span v-else-if="col.name == 'Maps' && queryMap == 'ALL'">
                      <q-btn>
                        <img src='/images/hero.png'>
                        <q-tooltip class="bg-deep-purple text-body2">
                          <div :id="`maps_${index}`" class="">
                            <span v-for="time, map in row[col.prop]" :key="time">{{ map.slice(4).replace(/([A-Z])/g, ' $1').trim() }}: {{ timeConverter(time, mapTime) }}<br></span>
                          </div>
                        </q-tooltip>
                      </q-btn>
                    </span>
                    <span v-else-if="col.name == 'Bosses' && queryMap == 'ALL'">
                      <q-btn>
                        <img src='/images/boss.png'>
                        <q-tooltip class="bg-negative text-body2">
                          <div :id="`boss_${index}`" class="">
                            <span v-for="time, map in row[col.prop]" :key="time">{{ map.replaceAll('_', ' ') }}: {{ timeConverter(time, mapTime) }}<br></span>
                          </div>
                        </q-tooltip>
                      </q-btn>
                    </span>
                    <span v-else-if="col.name == 'Name'">
                        <router-link :to="`/player/${row.Id}`" >{{ row[col.prop] }}</router-link>
                    </span>
                    <span v-else-if="col.name == 'Date' || col.name == 'Hero'">
                      {{ row[col.prop] }}
                    </span>
                  </td>
                </template>
                <template v-else>
                  <td class="text-center">{{ row.Name }}</td>
                </template>
              </tr>
            </template>
          </q-virtual-scroll>
        </div>
      </div>
      <q-page-sticky expand position="top">
        <q-toolbar class="bg-grey-10 text-white">
          <img src="../../public/images/timer.png">
          <q-toolbar-title>
            Fastest Times <template v-if='querySeason !="Live"'>({{ seasonSelected }})</template> <template v-if='queryMap !="ALL"'>- {{ mapSelected }}</template>
          </q-toolbar-title>
        </q-toolbar>
      </q-page-sticky>
    </q-page>
  </q-page-container>
</template>

<style>
img {
  max-width:32px;
}

a {
  text-decoration: none;
  color: var(--q-primary);
}
</style>

<script>
import { mapState } from 'vuex';
import store from '../js/store';
import { getQueryResults } from '../js/query';
import { timeConverter } from '../js/functions';

// TODO: Cleanup the props/store/labels/etc so its all centralized.

export default {
  name: 'Main',
  props: {
    sessionTime: String,
    mapTime: String,
    mapSelected: String,
    seasonSelected: String,
    heroIcons: Boolean
  },
  data() {
    return {
      columnData: [ 
        { name: 'Name', prop: 'Name' },
        { name: 'Time', prop: 'SessionTime' },
        { name: 'Hero', prop: 'HeroUsed' },
        { name: 'Date', prop: 'Moment' },
        { name: 'Bosses', prop: 'BossMaps' },
        { name: 'Maps', prop: 'NormalMaps' },
        //{ name: 'Platform', prop: 'Platform' },
      ],
      timeConverter
    }
  },
  computed: {
    ...mapState(['boardData', 'queryAmt', 'querySeason', 'queryMap']),
  },
  methods: {
    async getInitialData() {
      getQueryResults(true);
    },
    scroll(details) {
      if (details.index > this.queryAmt - 35) {
        store.dispatch('storeQueryAmt');
      }
    },
  },
  mounted() {
    this.getInitialData();
  }
}
</script>
